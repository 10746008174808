import baseModel from '@/models/base-model-new'
import format from 'date-fns/format'

export default {
  setup(model) {
    return baseModel.setup(model, 'shiftPlan', {
      parseResponse(response) {
        return {
          shift_plan_enabled: false,
          shift_plan: response.data.map((shift) => {
            return {
              ...shift,
              date: format(new Date(shift.date), 'yyyy-MM-dd')
            }
          })
        }
      },
      parseDataBeforeSave(shiftPlan) {
        return {
          shift_plan_enabled: shiftPlan.shift_plan_enabled,
          shift_plan: shiftPlan.shift_plan.map((item) => {
            return {
              date: format(new Date(item.date), 'yyyy-MM-dd'),
              staff_member_id: item.staff_member_id,
              plan: item.plan
            }
          })
        }
      }
    })
  }
}
