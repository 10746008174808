import {
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  addDays,
  format,
  parse
} from 'date-fns'
import { formatDateToISO } from '@/utils/date'

/**
 * @param {Date} startDate
 * @returns {{ start: string, end: string }}
 */
function getWeekDateRange(startDate) {
  const start = startOfWeek(startDate, {
    weekStartsOn: 1
  })

  const end = endOfWeek(startDate, {
    weekStartsOn: 1
  })

  return {
    start: formatDateToISO(start),
    end: formatDateToISO(end)
  }
}

export function getCurrentWeekDateRange() {
  return getWeekDateRange(new Date())
}

/**
 * @param {{ start: string | Date , end: string | Date }} dateRange
 */
export function getNextWeekDateRange(dateRange) {
  return getWeekDateRange(addWeeks(new Date(dateRange.start), 1))
}

/**
 * @param {{ start: string | Date , end: string | Date }} dateRange
 */
export function getPreviousWeekDateRange(dateRange) {
  return getWeekDateRange(subWeeks(new Date(dateRange.start), 1))
}

/**
 *
 * @param {Date} startDate
 * @returns {{ date: Date, label: string }[]}
 */
export function getWeekdays($t, startDate) {
  startDate = parse(startDate, 'yyyy-MM-dd', new Date())

  const weekDays = [
    $t('common.days.monday'),
    $t('common.days.tuesday'),
    $t('common.days.wednesday'),
    $t('common.days.thursday'),
    $t('common.days.friday'),
    $t('common.days.sunday'),
    $t('common.days.saturday')
  ]

  return Array.from({ length: 7 }).map((_, index) => {
    const date = addDays(startDate, index)
    return {
      date: format(date, 'yyyy-MM-dd'),
      label: weekDays[index].slice(0, 3) + ' ' + format(date, 'dd/M')
    }
  })
}

export function groupBy(property, items) {
  return items.reduce((acc, item) => {
    const key = item[property]

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(item)

    return acc
  }, {})
}
