<template>
  <th-wrapper v-loading="isLoadingData" class="m-8">
    <th-input-title class="inline-flex justify-center p-0 mb-4">
      <el-switch
        class="mr-2"
        :model-value="current.shift_plan_enabled"
        @update:model-value="toggleShiftPlan($event)"
      />

      <span
        class="font-semibold transition duration-150"
        :class="{
          'text-th-primary': current.shift_plan_enabled
        }"
      >
        {{ $t('common.resource.shift_plan.singular') }}
      </span>
    </th-input-title>

    <p class="mb-6 mt-0 text-sm">
      {{ $t('pages.staff.shift_plan.form.explainer') }}
    </p>

    <header
      class="relative flex items-center justify-center sm:justify-between xs:justify-between mb-4"
    >
      <div
        class="absolute xs:relative sm:relative inset-y-0 left-0 flex items-center mr-2"
      >
        <el-button class="h-8" size="small" @click="selectCurrentWeek">
          {{ $t('pages.staff.shift_plan.form.this_week') }}
        </el-button>
      </div>

      <week-selector
        :model-value="dateRange"
        @update:model-value="setDateRange"
      />
    </header>

    <el-table :data="resources.staff">
      <el-table-column
        width="200"
        :formatter="getStaffName"
        prop="staff.nameFormatted"
        :label="$t('common.titles.name')"
      />

      <el-table-column
        v-for="(weekday, index) in weekdays"
        :key="index"
        v-slot="{ row }"
        min-width="295"
        prop="label"
        :label="weekday.label"
        class-name="align-top"
      >
        <staff-day-shifts-form
          ref="dayShiftsForms"
          :date="weekday.date"
          :staff="row"
          :shifts="shiftsByStaffId[row.id] || []"
          @add-shift="addShift(row, weekday.date)"
          @remove-shift="removeShift(row, weekday.date, $event)"
        />
      </el-table-column>
    </el-table>
  </th-wrapper>
</template>
<script>
import StaffDayShiftsForm from './staff-day-shifts-form.vue'
import WeekSelector from './week-selector.vue'
import { getCurrentWeekDateRange, getWeekdays, groupBy } from '../utils'
import shiftPlanModel from '../shift-plan-model'

export default {
  name: 'ShiftPlanForm',

  components: {
    WeekSelector,
    StaffDayShiftsForm
  },

  props: {
    resources: {
      type: Object,
      required: true
    }
  },

  setup() {
    const { current, fetch, save, id } = shiftPlanModel.setup({
      shift_plan_enabled: false,
      shift_plan: []
    })

    return {
      current,
      fetch,
      save,
      id
    }
  },

  data() {
    return {
      isLoadingData: true,
      dateRange: getCurrentWeekDateRange()
    }
  },

  computed: {
    // The oldest branch is considered the default one, because the feature does not currently
    // support multiple branches. The backend implements the same logic
    selectedBranch() {
      const branchesSorted = this.resources.branches.slice().sort((a, b) => {
        return new Date(a.created_at.unix) - new Date(b.created_at.unix)
      })

      return branchesSorted[0] ?? null
    },

    shiftsByStaffId() {
      return groupBy('staff_member_id', this.current.shift_plan)
    },

    weekdays() {
      const { start } = this.dateRange

      return getWeekdays(this.$t, start)
    }
  },

  async created() {
    this.id = this.selectedBranch.id

    const response = await this.fetch()

    this.current.shift_plan_enabled =
      this.selectedBranch?.shift_plan_enabled ?? false

    this.isLoadingData = false

    if (response?.error) {
      const { error } = response

      const is404 = error.message.includes('404')

      if (is404) {
        // 404 requests are fine because backend returns 404 when no
        // shifts have been added
        return
      }

      this.$logException(error, { trackError: false })
    }
  },

  methods: {
    getStaffName({ firstname, lastname }) {
      if (firstname && lastname) {
        return `${firstname} ${lastname}`
      }

      return firstname || lastname
    },

    removeShift(staff, date, shiftIndex) {
      const dailyShifts = this.current.shift_plan.find((shift) => {
        return shift.staff_member_id === staff.id && shift.date === date
      })

      if (!dailyShifts) {
        return
      }

      dailyShifts.plan.splice(shiftIndex, 1)
    },

    addShift(staff, date) {
      const dailyShifts = this.current.shift_plan.find((shift) => {
        return shift.staff_member_id === staff.id && shift.date === date
      })

      const shift = {
        start: '09:00',
        end: '17:00'
      }

      if (dailyShifts) {
        dailyShifts.plan.push(shift)
        return
      }

      this.current.shift_plan.push({
        staff_member_id: staff.id,
        date,
        plan: [shift]
      })
    },

    /**
     * @public
     */
    async submitForm() {
      const isValid = await this.validateCurrentWeekShifts()

      if (!isValid) {
        return
      }

      // The initial value for this is coming from the branch, not the shift plan entity
      // and when `save` is called, the backend will not return it, so we have to sync it
      // manually
      const isEnabled = this.current.shift_plan_enabled

      const { error } = await this.save()

      if (error) {
        this.$logException(error, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.shift_plan.singular')
          })
        })

        return
      }

      this.current.shift_plan_enabled = isEnabled

      this.$message({
        type: 'success',
        message: this.$t('common.success.action.update.single', {
          resource: this.$t('common.resource.shift_plan.singular')
        })
      })
    },

    async validateCurrentWeekShifts() {
      for (const form of this.$refs.dayShiftsForms) {
        const isValid = await form.validate()

        if (!isValid) {
          this.$message({
            type: 'warning',
            grouping: true,
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })

          return false
        }
      }

      return true
    },

    async setDateRange(range) {
      const isValid = await this.validateCurrentWeekShifts()

      // If the user enters invalid data for any of the shifts for the current week
      // we dont want to let him switch weeks, because it would be very hard for him
      // to find the errors when the form is saved and we run the validator.
      // He can only move on if all data is valid
      if (!isValid) {
        return
      }

      this.dateRange = range
    },

    selectCurrentWeek() {
      this.setDateRange(getCurrentWeekDateRange())
    },

    toggleShiftPlan(isActivated) {
      this.current.shift_plan_enabled = isActivated

      this.$ampli.eventWithBaseProps('shiftPlanToggled', {
        is_activated: isActivated
      })
    }
  }
}
</script>
