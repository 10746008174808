<template>
  <el-form ref="form" :model="shiftsForTheDay">
    <shift-time-select
      v-for="(shift, index) in shiftsForTheDay"
      :key="date + staff.id + index"
      class="mb-2"
      :shift="shift"
      :index="index"
      @remove="$emit('removeShift', index)"
    />

    <el-button plain type="primary" class="h-8" @click="$emit('addShift')">
      {{ $t('common.interactions.buttons.add') }}
    </el-button>
  </el-form>
</template>
<script>
import ShiftTimeSelect from './shift-time-select.vue'

export default {
  name: 'StaffDayShiftsForm',
  components: {
    ShiftTimeSelect
  },
  props: {
    staff: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    shifts: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  emits: ['addShift', 'removeShift'],
  computed: {
    shiftsForTheDay() {
      const dailyPlan = this.shifts.find((shift) => {
        return shift.date === this.date
      })

      return dailyPlan?.plan ?? []
    }
  },
  methods: {
    /**
     * @public
     */
    reset() {
      this.$refs.form.resetFields()
    },
    /**
     * @public
     */
    async validate() {
      if (this.shiftsForTheDay.length === 0) {
        return true
      }

      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
