<template>
  <div class="time-range-select flex items-center">
    <time-select
      v-model="localShift.start"
      size="small"
      start="00:00"
      end="23:45"
      :clearable="false"
      :step="step"
      @update:model-value="validateEndsAtField"
    />

    <span class="mx-2 whitespace-no-wrap">
      {{ $t('pages.staff.shift_plan.form.week_selector.to') }}
    </span>

    <el-form-item
      ref="endsAtField"
      :rules="endsAtValidationRule"
      :prop="`${index}.end`"
      :show-message="false"
      class="block mb-0 leading-none"
    >
      <time-select
        v-model="localShift.end"
        size="small"
        :clearable="false"
        :start="localShift.start"
        end="23:45"
        :step="step"
      />
    </el-form-item>

    <button
      class="ml-2 text-lg leading-none inline-flex items-center"
      type="button"
      @click="$emit('remove')"
    >
      <el-icon>
        <close />
      </el-icon>
    </button>
  </div>
</template>

<script>
import { parse } from 'date-fns'
import TimeSelect from '@/components/time-select/index.vue'

export default {
  name: 'ShiftTimeSelect',
  components: { TimeSelect },
  props: {
    shift: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['remove'],
  data() {
    return {
      step: '00:15',
      endsAtValidationRule: {
        validator: this.endsAtValidationFn,
        trigger: 'change'
      }
    }
  },
  computed: {
    localShift() {
      return this.shift
    }
  },

  methods: {
    validateEndsAtField() {
      this.$refs.endsAtField.validate(null, () => {})
    },

    endsAtValidationFn(_, __, callback) {
      const start = parse(this.localShift.start, 'HH:mm', new Date())
      const end = parse(this.localShift.end, 'HH:mm', new Date())

      const startsBeforeEnds = new Date(start) < new Date(end)

      if (startsBeforeEnds) {
        return callback()
      }

      const error = new Error(
        this.$t(
          'pages.staff.shift_plan.form.errors.shift_start_time_is_after_end_time'
        )
      )

      callback(error)

      this.$message({
        type: 'error',
        message: error.message,
        grouping: true
      })
    }
  }
}
</script>

<style scoped>
.time-range-select:deep(.el-input) {
  min-height: 32px;
}

/* Set to 40px by default, it causes misalignment with the inputs */
.time-range-select:deep(.el-form-item__content) {
  line-height: 1;
}
</style>
