<template>
  <div
    class="w-full h-full flex flex-col bg-th-navigation"
    data-testid="service-category-empty"
  >
    <div class="flex justify-between items-center py-6 pr-6 pl-8">
      <div
        class="h-full flex flex-col flex-end font-bold leading-7 text-lg"
        :class="{ italic: !isWhiteLabel }"
      >
        <div>
          {{ $t('pages.staff.shift_plan.empty.text.title') }}
        </div>
        <div>
          {{ $t('pages.staff.shift_plan.empty.text.sub') }}
        </div>
      </div>

      <el-button
        type="primary"
        class="flex items-center h-th-action-button-height"
        @click="buttons[0].clickHandler"
      >
        <svgicon
          :src="require('@/assets/icons/th-icon-plus.svg')"
          :style="{ height: '20px', width: '20px' }"
          class="mr-1 fill-current"
        />
        {{ buttons[0].label }}
      </el-button>
    </div>
    <div class="h-full flex min-h-0 min-w-0 items-end justify-center">
      <img
        src="@/assets/illustrations/reservations-empty.svg"
        alt="products-list-empty"
        style="max-width: 80%; max-height: 71vh"
      />
    </div>
    <div class="th-shadow-top h-20" />
  </div>
</template>

<script>
import { isUnifiedCommerce } from '@/constants'

export default {
  name: 'ShiftPlanEmpty',
  data() {
    return {
      resources: {},
      buttons: [
        {
          // @TODO shift plan scope?
          // scopes: ['service_category:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.forms.labels.new'),
          clickHandler: this.handleNew
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'staff-new' })
    }
  }
}
</script>

<style scoped>
.th-shadow-top {
  box-shadow: 0px -4px 4px rgba(167, 171, 177, 0.15);
}
</style>
