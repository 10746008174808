<template>
  <div class="flex items-center">
    <el-button
      class="h-8 w-8"
      type="primary"
      icon="ArrowLeft"
      @click="goToPreviousWeek"
    />

    <div class="mx-4 text-sm truncate">
      {{ dateRangeFormatted }}
    </div>

    <el-button
      class="h-8 w-8"
      type="primary"
      icon="ArrowRight"
      @click="goToNextWeek"
    />
  </div>
</template>

<script>
import { formatDateRange } from '@/utils/date'
import { getNextWeekDateRange, getPreviousWeekDateRange } from '../utils'

export default {
  name: 'WeekSelector',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    dateRangeFormatted() {
      return formatDateRange(this.modelValue)
    }
  },
  methods: {
    goToNextWeek() {
      const value = getNextWeekDateRange(this.modelValue)

      this.$emit('update:modelValue', value)
    },
    goToPreviousWeek() {
      const value = getPreviousWeekDateRange(this.modelValue)

      this.$emit('update:modelValue', value)
    }
  }
}
</script>
