<template>
  <div
    v-if="loading || resources.staff.length === 0"
    v-loading="loading"
    class="h-full"
  >
    <empty v-if="loading === false && resources.staff.length === 0" />
  </div>

  <th-page-wrapper
    v-else
    :actions="{}"
    :exclude-buttons="['delete', 'back']"
    @save="onSave"
  >
    <shift-plan-form ref="form" :resources="resources" />
  </th-page-wrapper>
</template>
<script>
import ShiftPlanForm from './components/form.vue'
import Empty from './empty.vue'

export default {
  name: 'ShiftPlanIndex',

  components: {
    ShiftPlanForm,
    Empty
  },

  data() {
    return {
      loading: true,
      resources: {
        branches: [],
        staff: []
      }
    }
  },

  async created() {
    await this.fetchResources()
  },

  methods: {
    async fetchResources() {
      try {
        const { branchesV1, staff } = await this.$resourceFetch(
          {
            resource: 'branchesV1',
            query: {
              deleted: false
            }
          },
          {
            resource: 'staff'
          }
        )

        this.resources = {
          branches: branchesV1,
          staff
        }
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    async onSave() {
      await this.$refs.form.submitForm()
    }
  }
}
</script>
